<script setup>
// import { computed } from 'vue'
const auth = useAuthStore()
const config = useRuntimeConfig()
const props = defineProps({
  id: {
    type: Number,
    default: null
  },
  absolute: {
    type: Boolean,
    deafult: false
  },
  type: {
    type: String,
    default: 'page'
  }
})

// cms/wp-admin/term.php?taxonomy=product_cat&tag_ID=17&post_type=product&wp_http_referer=%2Fcms%2Fwp-admin%2Fedit-tags.php%3Ftaxonomy%3Dproduct_cat%26post_type%3Dproduct
const href = computed(() => {
  if (props.type === 'taxonomy') {
    return config.public.CMS + '/wp-admin/term.php?taxonomy=product_cat&tag_ID=' + props.id + '&post_type=product'
  }
  else if (props.type === 'plocasti-materijali') {
    return config.public.CMS + '/wp-admin/term.php?taxonomy=kategorije&tag_ID=' + props.id + '&post_type=' + props.type
  }
  else {
    return config.public.CMS + '/wp-admin/post.php?post=' + props.id + '&action=edit' + '&post_type=' + props.type
  }
})
const stop = e => e.stopPropagation()
</script>

<template>
  <ClientOnly>
    <a
      v-if="id && (auth.isAdmin)"
      aria-label="Edit"
      :class="['edit', { absolute }]"
      :href="href"
      target="_self"
      @click="stop"
    >
      <svg
        height="16"
        viewBox="0 0 24 24"
        width="16"
        fill="white"
      >
        <path
          d="M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z"
        />
      </svg>
    </a>
  </ClientOnly>
</template>

<style scoped>
.edit {
  --size: 32px;
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 10;
  color: white;
  background-color: var(--brand-color);
  cursor: pointer;
  width: var(--size);
  height: var(--size);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  &.absolute {
    position: absolute;
    bottom: 4px;
    left: 4px;
  }
}
</style>
